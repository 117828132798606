import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const Brand1_content = [
	{
		thumb: require("../../assets/images/resource/partner1.png"),
		title: "Huawei",
	},
	{
		thumb: require("../../assets/images/resource/partner2.png"),
		title: "Lukanga Water & Sewerage Co.",
	},
	{
		thumb: require("../../assets/images/resource/partner3.png"),
		title: "Kabwe Municipal Council",
	},
	{
		thumb: require("../../assets/images/resource/partner4.png"),
		title: "Energy Regulatory Board Zambia",
	},
	{
		thumb: require("../../assets/images/resource/partner5.png"),
		title: "Upgrade Energy Africa",
	},
	{
		thumb: require("../../assets/images/resource/partner6.png"),
		title: "Zambia Environmental Management Agency",
	},
	{
		thumb: require("../../assets/images/resource/partner7.png"),
		title: "ZESCO",
	},
	{
		thumb: require("../../assets/images/resource/partner8.png"),
		title: "China Jiangxi MFEZ",
	},
]


class Brand1 extends Component {


    render() {

        const settings = {
			accessibility: true,
			dots: false,
			infinite: true,
			pauseOnHover: true,
			arrows: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000,
			cssEase: "linear",
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						infinite: true,
						autoplay: true,
						autoplaySpeed: 3000,
						cssEase: "linear",
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						autoplay: true,
						autoplaySpeed: 3000,
						cssEase: "linear",
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						autoplay: true,
						autoplaySpeed: 3000,
						cssEase: "linear",
					},
				},
			],
		}
        return (
			<>
				{/* <!-- Sponsors Section --> */}
				<section class="sponsors-section pt-0">
					<div class="container-fluid">
						{/* <!--Sponsors Carousel--> */}
						<div className="row">
							<div
								className="col-lg-12"
								// style={{ height: "50px" }}
							>
								<Slider
									{...settings}
									className="theme_carousel"
								>
									{Brand1_content.map((item, id) => (
										<div class="slide-item" key={id}>
											<figure class="image-box">
												<img src={item.thumb} alt="" title={item.title} />
												{/* <figcaption>{item.title}</figcaption> */}
											</figure>
										</div>
									))}
								</Slider>
							</div>
						</div>
					</div>
				</section>
			</>
		)
    }
}
export default Brand1;