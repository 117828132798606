import React, { Component } from "react"
import { Link } from "react-scroll"
import Menu from "./menu"

class Header3 extends Component {
	state = {
		scrolled: false,
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll)

		var mobilebtn = document.querySelector(".mobile-nav-toggler")
		var closeBtn = document.querySelector(".close-btn")
		var body = document.getElementsByTagName("body")[0]

		function addFunc() {
			return body.classList.add("mobile-menu-visible")
		}
		function closeFunc() {
			return body.classList.remove("mobile-menu-visible")
		}

		mobilebtn.addEventListener("click", addFunc)
		closeBtn.addEventListener("click", closeFunc)

		window.addEventListener("scroll", this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll)
	}

	handleScroll = () => {
		var body = document.getElementsByTagName("body")[0]
		body.classList.remove("mobile-menu-visible")
			
		if (window.scrollY > 100) {
			this.setState({ scrolled: true })
		} else {
			this.setState({ scrolled: false })
		}
	}

	scrollToSection = (section) => {
		// Implement your scrolling logic here
	}

	render() {
		const { scrolled } = this.state
		const { children } = this.props

		return (
			<>
				<header
					className={
						scrolled
							? "main-header header-style-three fixed-header"
							: "main-header header-style-three"
					}
				>
					{/* Header Upper */}
					<div className="header-upper">
						<div className="auto-container" style={{maxWidth:"1200px"}}>
							<div className="inner-container">
								{/* Nav Box */}
								<div className="nav-outer clearfix">
									{/* Logo */}
									<div className="logo-box">
										<div className="logo">
											<Link to={"/index-2"}>
												<img
													src={require("../../assets/images/logo.png")}
													alt=""
												/>
											</Link>
										</div>
									</div>
									{/* Sidemenu Navigation Toggler */}
									<div className="sidemenu-nav-toggler">
										<span className="icon fal fa-bars"></span>
									</div>
									{/* Mobile Navigation Toggler */}
									<div className="mobile-nav-toggler">
										<span className="icon fal fa-bars"></span>
									</div>

									{/* Main Menu */}
									<nav className="main-menu navbar-expand-md navbar-light pull-right">
										<Menu
											scrollToSection={
												this.scrollToSection
											}
										/>
									</nav>
									{/* Main Menu End */}

									{/* Header Right Content */}
									{/* <div className="header-right-content clearfix">
										<div className="contact-info">
											<div className="icon-box">
												<i className="flaticon-call-1"></i>
											</div>
											<div className="text">
												Phone Number
											</div>
											<h4>
												<Link to="/tel:+260978010015">
													+260 97 801 0015
												</Link>
											</h4>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					{/* End Header Upper */}

					{/* Sticky Header */}
					<div
						className={
							scrolled
								? "sticky-header animated slideInDown"
								: "sticky-header"
						}
					>
						<div className="auto-container clearfix">
							{/* Logo */}
							<div className="logo pull-left">
								<Link to={"/index-2"} title="">
									<img
										src={require("../../assets/images/sticky-logo-3.png")}
										alt=""
										title=""
									/>
								</Link>
							</div>
							{/* Right Col */}
							<div className="pull-right">
								{/* Main Menu */}
								<nav className="main-menu clearfix">
									<Menu
										scrollToSection={this.scrollToSection}
									/>
								</nav>
								{/* Main Menu End */}
							</div>
						</div>
					</div>
					{/* End Sticky Menu */}

					{/* Mobile Menu */}
					<div className="mobile-menu">
						<div className="menu-backdrop"></div>
						<div className="close-btn">
							<span className="icon flaticon-cancel"></span>
						</div>

						<nav className="menu-box">
							<div className="nav-logo">
								<Link to={"/index-2"}>
									<img
										src={require("../../assets/images/logo.png")}
										alt=""
										title=""
									/>
								</Link>
							</div>
							<div className="menu-outer">
								<Menu scrollToSection={this.scrollToSection} />
							</div>
							{/* Social Links */}
							{/* <div className="social-links">
								<ul className="clearfix">
									<li>
										<Link to={"/#"}>
											<span className="fab fa-twitter"></span>
										</Link>
									</li>
									<li>
										<Link to={"/#"}>
											<span className="fab fa-facebook-square"></span>
										</Link>
									</li>
									<li>
										<Link to={"/#"}>
											<span className="fab fa-pinterest-p"></span>
										</Link>
									</li>
									<li>
										<Link to={"/#"}>
											<span className="fab fa-instagram"></span>
										</Link>
									</li>
									<li>
										<Link to={"/#"}>
											<span className="fab fa-youtube"></span>
										</Link>
									</li>
								</ul>
							</div> */}
						</nav>
					</div>
					{/* End Mobile Menu */}

					<div className="nav-overlay remove"></div>
				</header>
				{/* End Main Header */}
				{children}
			</>
		)
	}
}

export default Header3
