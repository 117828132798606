import React, { Component, useContext } from 'react';
import { Link, scroller, Element} from 'react-scroll';
import ScrollContext from './scrollContext';

class Menu extends Component {

	handleClick = (event, section) => {
		event.preventDefault()
		this.props.scrollToSection(section)
	}

	render() {
		return (
			<div
				class="collapse navbar-collapse show clearfix"
				id="navbarSupportedContent"
			>
				<ul class="navigation clearfix">
					<li>
						<Link
							to="home"
							smooth="true"
							duration={800}
							offset={-50}
							onClick={(event) => this.handleClick(event, "home")}
						>
							Home
						</Link>
					</li>
					<li>
						<Link
							to="about"
							smooth="true"
							duration={800}
							offset={-50}
							onClick={(event) =>
								this.handleClick(event, "about")
							}
						>
							About Us
						</Link>
					</li>
					<li>
						<Link
							to="services"
							smooth="true"
							duration={800}
							offset={-50}
							onClick={(event) =>
								this.handleClick(event, "services")
							}
						>
							Services
						</Link>
					</li>
					<li>
						<Link
							to="contact"
							smooth="true"
							duration={800}
							offset={-250}
							onClick={(event) =>
								this.handleClick(event, "contact")
							}
						>
							Contact
						</Link>
					</li>
				</ul>
			</div>
		)
	}
}
export default Menu;