// import React from 'react';
import React, { Component, createRef, useRef } from 'react';
import Router from './markup/router';
import './assets/css/bootstrap.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/color.css'
import ScrollContext from './markup/layout/scrollContext';
import Index3 from './markup/pages/index-3';

class App extends Component {
	sectionRefs = {}

	scrollToSection = (section) => {
		if (this.sectionRefs[section]) {
			this.sectionRefs[section].scrollIntoView({
				behavior: "smooth",
				block: "start",
			})
		}
	}

	render() {
		return (
				<div className="App">
					<Index3
						sectionRefs={this.sectionRefs}
						scrollToSection={this.scrollToSection}
					/>
				</div>
		)
	}
}

export default App;
