import React, { Component } from "react"
import { Link } from "react-scroll"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import "../../assets/css/slider.css"

const slides = [
	{
		title: "Reviving Africa's Environment",
		btnLearn: "-- Learn More --",
		finan: "Empowering Clean Energy Solutions",
		dox: "",
		image: require("./../../assets/images/main-slider/12.jpg"),
	},
	{
		title: "Reviving Africa's Environment",
		dox: "Purifying Water for Healthy Communities",
		image: require("./../../assets/images/main-slider/11.jpg"),
	},
	{
		title: "Reviving Africa's Environment",
		finan: "Nurturing a Circular Economy",
		image: require("./../../assets/images/main-slider/Waste-scaled.jpg"),
	},
	{
		title: "Reviving Africa's Environment",
		finan: "Transforming Wastewater, Preserving Nature",
		image: require("./../../assets/images/main-slider/9.jpg"),
	},
]

class HomeSlider3 extends Component {
	render() {
		return (
			<>
				<Slider className="slider-wrapper" duration={"2000"} autoplay={"4000"}>
					{slides.map((slide, index) => (
						<div
							key={index}
							className="slider-content"
							style={{
								background: `url('${slide.image}') no-repeat center center`,    
							}}
						>
							<div className="inner text-center inner-2">
								<h1
									style={{
										fontSize: "4vmin",
										letterSpacing: "0",
                                        minWidth: "30px",
                                        opacity:"0.8"
									}}
								>
									{slide.title}
								</h1>
								<h2
									style={{
										fontSize: "12vmin",
										letterSpacing: "0",
									}}
								>
									{slide.finan} <span>{slide.dox} </span>
								</h2>
							</div>
						</div>
					))}
				</Slider>

				{/* <section class="banner-section style-three">
					<div
						class="banner-carousel theme_carousel owl-theme owl-carousel"
						data-options='{"loop": true, "margin": 0, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 300, "responsive":{ "0" :{ "items": "1" }, "768" :{ "items" : "1" } , "1000":{ "items" : "1" }}}'
					>
						<div class="slide-item">
							<div
								class="image-layer lazy-image"
								data-bg="url('assets/images/')"
							></div>

							<div class="auto-container">
								<div class="content-box">
									<h3>Making Your Business Idea</h3>
									<h2>
										We Take Care Of <br />
										The Future
									</h2>
									<div class="btn-box">
										<Link
											to={"/#"}
											class="theme-btn btn-style-one"
										>
											<span class="btn-title">
												{" "}
												Our Services{" "}
											</span>
										</Link>
										<Link
											to={"/#"}
											class="theme-btn btn-style-two"
										>
											<span class="btn-title">
												{" "}
												Learn More{" "}
											</span>
										</Link>
									</div>
								</div>
							</div>
						</div>

						<div class="slide-item">
                            <div class="image-layer lazy-image" data-bg="url('assets/images/main-slider/7.jpg')"></div>

                            <div class="auto-container">
                                <div class="content-box">
                                    <h3>Making Your Business Idea</h3>
                                    <h2>We Take Care Of <br/>The Future</h2>
                                    <div class="btn-box"><Link to={'/#'} class="theme-btn btn-style-one"><span class="btn-title">Our Services</span></Link><Link to={'/#'} class="theme-btn btn-style-two"><span class="btn-title">Learn More </span></Link></div>
                                </div>
                            </div>
                        </div>
					</div>
				</section> */}
			</>
		)
	}
}
export default HomeSlider3
