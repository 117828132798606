import React, { Component, createRef, useRef } from "react"
import { Nav, Tab } from "react-bootstrap"
import Header3 from "../layout/header3"
import HomeSlider3 from "../element/home-slider3"
import Footer2 from "../layout/footer2"
import { Link } from "react-scroll"
import Brand1 from "../element/brand1"

class Index3 extends Component {
	homeRef = createRef()
	aboutRef = createRef()
	servicesRef = createRef()
	contactRef = createRef()

	componentDidMount() {
		const { sectionRefs } = this.props
		sectionRefs.home = this.homeRef.current
		sectionRefs.about = this.aboutRef.current
		sectionRefs.services = this.servicesRef.current
		sectionRefs.contact = this.contactRef.current
	}

	render() {
		const { children, scrollToSection } = this.props
		return (
			<>
				<Header3 scrollToSection={scrollToSection}>{children}</Header3>

				{/* <!-- Hidden Sidebar --> */}

				{/* <!--Search Popup--> */}
				{/* <div id="search-popup" class="search-popup">
					<div class="close-search theme-btn">
						<span class="flaticon-cancel"></span>
					</div>
					<div class="popup-inner">
						<div class="overlay-layer"></div>
						<div class="search-form">
							<form
								method="post"
								action="http://azim.commonsupport.com/Finandox/index.html"
							>
								<div class="form-group">
									<fieldset>
										<input
											type="search"
											class="form-control"
											name="search-input"
											value=""
											placeholder="Search Here"
											required
										/>
										<input
											type="submit"
											value="Search Now!"
											class="theme-btn"
										/>
									</fieldset>
								</div>
							</form>
							<br />
							<h3>Recent Search Keywords</h3>
							<ul class="recent-searches">
								<li>
									<Link to={"/#"}>Finance</Link>
								</li>
								<li>
									<Link to={"/#"}>Idea</Link>
								</li>
								<li>
									<Link to={"/#"}>Service</Link>
								</li>
								<li>
									<Link to={"/#"}>Growth</Link>
								</li>
								<li>
									<Link to={"/#"}>Plan</Link>
								</li>
							</ul>
						</div>
					</div>
				</div> */}

				{/* <!-- Banner Section --> */}
				<div ref={this.homeRef} id="home">
					<HomeSlider3 />
				</div>
				{/* <!--End Banner Section --> */}

				{/* <!-- Why Choose Us Section --> */}
				<section
					class="why-chooseus-section"
					ref={this.aboutRef}
					id="about"
				>
					<div class="auto-container">
						<div class="row align-items-top">
							<div class="col-lg-6">
								<div class="image-wrapper">
									<div class="image-one">
										<img
											src={require("../../assets/images/shutterstock_326698985.0.jpg")}
											alt=""
											style={{
												maxWidth: "800px",
											}}
										/>
									</div>
									<div class="image-two">
										<img
											src={require("../../assets/images/16559855_605.jpg")}
											alt=""
											style={{
												maxWidth: "300px",
											}}
										/>
									</div>
								</div>
							</div>

							<div class="col-lg-6">
								<div class="content-box ml-lg-3">
									<div
										class="sec-title"
										style={{ marginBottom: "30px" }}
									>
										<div class="sub-title">About Us</div>
										<h2>Driving Sustainability Forward</h2>
									</div>
									<Tab.Container defaultActiveKey="first">
										<Nav
											variant="pills"
											className="nav nav-tabs tab-btn-style-one"
										>
											<Nav.Item>
												<Nav.Link eventKey="first">
													<span>Our Mission</span>
												</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="second">
													<span>Our Vision</span>
												</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="third">
													<span>Our Values</span>
												</Nav.Link>
											</Nav.Item>
										</Nav>
										<Tab.Content className="tabs-content">
											<Tab.Pane
												eventKey="first"
												className="fadeInUp animated"
											>
												<div class="clearfix">
													{/* <div class="image">
														<img
															src={require("../../assets/images/resource/image-4.jpg")}
															alt=""
														/>
													</div> */}
													<div class="text">
														<p>
															<b>Our mission </b>
															is to restore,
															recycle, and
															responsibly utilize
															all of Mother
															Earth's finite
															resources, striving
															for the rejuvenation
															of Africa's
															negatively impacted
															environment and
															health levels.
															Through
															well-thought-out,
															sustainable, and
															green carbon-neutral
															initiatives, we aim
															to implement
															best-practice
															methods and systems
															at every stage of a
															project's life
															cycle. By combining
															modern technology,
															local knowledge, and
															cultural wisdom, we
															seek to deliver
															innovative and
															sustainable
															solutions that make
															optimal use of the
															best quality
															materials. With our
															expertise in solar
															and electrical power
															supply, clean water
															supply, wastewater
															treatment, and solid
															waste management, we
															are dedicated to
															driving positive
															change and creating
															a brighter, greener
															future for Africa.
														</p>
													</div>
												</div>
											</Tab.Pane>
											<Tab.Pane
												eventKey="second"
												className="fadeInUp animated"
											>
												<div class="clearfix">
													{/* <div class="image">
														<img
															src={require("../../assets/images/resource/image-4.jpg")}
															alt=""
														/>
													</div> */}
													<div class="text">
														<p>
															<b>Our vision </b>
															is to witness a
															transformative
															Africa, where the
															negative impacts on
															the environment and
															health are reversed
															through visionary,
															sustainable, and
															green carbon-neutral
															initiatives. We
															aspire to create a
															future where
															Africa's landscapes
															thrive, embracing a
															harmonious balance
															between human
															activities and
															nature's abundance.
															Drawing on our
															expertise and
															best-practice
															methods, we are
															committed to
															implementing
															innovative solutions
															throughout the
															entire project life
															cycle. By combining
															cutting-edge
															technologies, local
															knowledge, and
															cultural diversity,
															we strive to develop
															sustainable
															interventions that
															preserve our finite
															resources
															responsibly. With a
															strong focus on
															solar and electrical
															power supply, clean
															water provision,
															wastewater
															treatment, and solid
															waste management, we
															aim to be
															trailblazers in
															driving positive
															change. Our goal is
															to empower
															communities with
															vibrant environments
															that enhance their
															well-being and
															foster sustainable
															development.
															Together, we can
															realize an Africa
															where sustainable
															practices are the
															norm, and the
															restoration of our
															environment becomes
															a catalyst for a
															brighter, greener
															future for
															generations to come.
														</p>
													</div>
												</div>
											</Tab.Pane>
											<Tab.Pane
												eventKey="third"
												className="fadeInUp animated"
											>
												<div class="clearfix">
													{/* <div class="image">
														<img
															src={require("../../assets/images/resource/image-4.jpg")}
															alt=""
														/>
													</div> */}
													<div class="text">
														<p>
															<p>
																These values
																guide our
																decisions,
																actions, and
																commitment to
																creating a
																sustainable and
																impactful
																future.
															</p>
															<ul>
																<li>
																	<b>
																		Sustainability:
																	</b>
																	We are
																	dedicated to
																	environmentally
																	responsible
																	and socially
																	conscious
																	practices.
																	Integrity:
																	We uphold
																	honesty,
																	transparency,
																	and ethical
																	conduct in
																	all our
																	interactions.
																</li>
																<li>
																	<b>
																		Excellence:
																	</b>
																	We strive
																	for
																	exceptional
																	quality,
																	innovation,
																	and
																	continuous
																	improvement.
																</li>
																<li>
																	<b>
																		Collaboration:
																	</b>
																	We value
																	partnerships
																	and embrace
																	diverse
																	perspectives
																	for
																	collective
																	success.
																</li>
																<li>
																	<b>
																		Respect:
																	</b>
																	We treat
																	individuals,
																	cultures,
																	and
																	communities
																	with respect
																	and
																	appreciation.
																</li>
																<li>
																	<b>
																		Stewardship:
																	</b>
																	We
																	responsibly
																	manage
																	resources
																	and promote
																	waste
																	reduction.
																</li>
																<li>
																	<b>
																		Social
																		Impact:
																	</b>
																	We aim to
																	make a
																	positive
																	difference
																	in
																	communities
																	and promote
																	well-being.
																</li>
															</ul>
														</p>
													</div>
												</div>
											</Tab.Pane>
										</Tab.Content>
									</Tab.Container>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section>
					<div class="auto-container">
						<div class="sec-title text-center">
							<div class="sub-title">Our Partners</div>
							{/* <h2>What We Do</h2> */}
						</div>
						<Brand1 />
					</div>
				</section>

				{/* <!-- Services Section Three --> */}
				<section
					class="services-section-three"
					ref={this.servicesRef}
					id="services"
				>
					<div class="auto-container">
						<div class="sec-title text-center">
							<div class="sub-title">Services</div>
							<h2>What We Do</h2>
						</div>
						<div class="row">
							{/* <!-- Services Block One --> */}
							<div class="service-block-one col-lg-6">
								<div class="inner-box">
									<div class="icon">
										<span class="flaticon-innovation"></span>
										<span class="round-shape"></span>
									</div>
									<h3>Solar & Electrical Power Supply</h3>
									<div class="text">
										Our team of experts specializes in
										scoping, designing, ensuring compliance,
										and installing solar and electrical
										power supply systems. We harness the
										power of renewable energy to provide
										reliable and sustainable electricity
										solutions. From residential to
										commercial projects, we deliver tailored
										designs and efficient reticulation
										systems, paving the way for a greener
										future.
									</div>
								</div>
							</div>
							{/* <!-- Services Block One --> */}
							<div class="service-block-one col-lg-6">
								<div class="inner-box">
									<div class="icon">
										<span class="flaticon-life-insurance"></span>
										<span class="round-shape"></span>
									</div>
									<h3>Clean Water Supply</h3>
									<div class="text">
										Our comprehensive clean water supply
										services encompass analysis, testing,
										water treatment design, reticulation
										planning, tank design, and compliance
										assurance. We are dedicated to ensuring
										access to safe and potable water for
										communities. Through innovative
										technologies and meticulous planning, we
										deliver reliable and sustainable clean
										water solutions, improving health and
										well-being.
									</div>
								</div>
							</div>
							{/* <!-- Services Block One --> */}
							<div class="service-block-one col-lg-6">
								<div class="inner-box">
									<div class="icon">
										<span class="flaticon-help-1"></span>
										<span class="round-shape"></span>
									</div>
									<h3>Wastewater Treatment</h3>
									<div class="text">
										Our wastewater treatment services cover
										comprehensive analysis, treatment plant
										design, pumping system implementation,
										and reticulation design to ensure
										compliance. We focus on transforming
										wastewater into valuable resources,
										promoting sustainability and minimizing
										environmental impact. By implementing
										effective treatment processes, we help
										communities and industries manage
										wastewater responsibly.
									</div>
								</div>
							</div>
							{/* <!-- Services Block One --> */}
							<div class="service-block-one col-lg-6">
								<div class="inner-box">
									<div class="icon">
										<span class="flaticon-team"></span>
										<span class="round-shape"></span>
									</div>
									<h3>Solid Waste Management</h3>
									<div class="text">
										Our solid waste management services
										encompass the entire waste management
										cycle, including scoping, collection,
										sorting, recycling, landfill design,
										civil engineering, power generation, and
										compliance assurance. We adopt an
										integrated approach, promoting waste
										reduction, resource recovery, and
										sustainable disposal. Our solutions
										contribute to a circular economy,
										fostering environmental stewardship and
										minimizing waste's negative impact.
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* <!-- About Section Two --> */}
				<section
					class="about-section-three"
					ref={this.contactRef}
					id="contact"
				>
					<div class="auto-container">
						<div
							class="wrapper-box"
							style={{
								marginTop: "-250px",
								backgroundColor: "white",
								paddingTop: "80px",
								paddingBottom: "40px",
							}}
						>
							<div class="row align-items-center">
								{/* <div class="col-lg-2">
									<div class="sec-title text-right">
										<div class="sub-title">Contact Us</div>
										<h2>
											Contact Us
										</h2>
									</div>
								</div> */}
								<div class="col-lg-12">
									<div class="content-box">
										<div class="row">
											<div class="info-column col-md-12">
												<div class="icon-box-first">
													<div>
														<div class="icon">
															<img
																src={require("../../assets/images/icons/locations.png")}
																alt=""
															/>
														</div>
														<h5>
															Physical Address
														</h5>
													</div>
													<h2>
														Norwich Insurance
														Brokers House, <br />
														Plot 6844,
														<br /> Cnr. Katima
														Mulilo & Bende Roads,
														<br />
														Olympia Park,
														<br />
														Lusaka, Zambia
													</h2>
												</div>
											</div>
											<div class="info-column col-md-6">
												<div class="icon-box">
													<div class="icon">
														<img
															src={require("../../assets/images/icons/icon-19.png")}
															alt=""
														/>
													</div>
													<h5>Phone Number</h5>
													<h2>+260 97 801 0015</h2>
													<h2>+260 97 750 3738</h2>
												</div>
											</div>
											<div class="info-column col-md-6">
												<div class="icon-box">
													<div class="icon">
														<img
															src={require("../../assets/images/icons/icon-20.png")}
															alt=""
														/>
													</div>
													<h5>Email Address</h5>
													<h2>info@abena.africa</h2>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* <!-- Contact Section Two --> */}
				<section class="contact-section-two">
					<div class="auto-container">
						{/* <div class="contact-info-area">
							<div class="contact-info">
								<div class="row">
									<div
										class="info-column col-lg-4 wow fadeInUp"
										data-wow-delay="200ms"
										data-wow-duration="1200ms"
									>
										<div class="icon-box">
											<div class="icon">
												<span class="flaticon-email-6"></span>
											</div>
											<h3>Email Address</h3>
											<ul>
												<li>
													<Link
														to={
															"/mailto:info@webmail.com"
														}
													>
														info@webmail.com
													</Link>
												</li>
												<li>
													<Link
														to={
															"/mailto:info@webmail.com"
														}
													>
														jobs@exampleco.com
													</Link>
												</li>
											</ul>
										</div>
									</div>
									<div
										class="info-column col-lg-4 wow fadeInUp"
										data-wow-delay="200ms"
										data-wow-duration="1200ms"
									>
										<div class="icon-box">
											<div class="icon">
												<span class="flaticon-call-1"></span>
											</div>
											<h3>Phone Number</h3>
											<ul>
												<li>
													<Link
														to={
															"/tel:+8976765654654"
														}
													>
														+897 676 5654 654
													</Link>
												</li>
												<li>
													<Link
														to={
															"/tel:+908(097)56476576"
														}
													>
														+908(097) 564 765 76
													</Link>
												</li>
											</ul>
										</div>
									</div>
									<div
										class="info-column col-lg-4 wow fadeInUp"
										data-wow-delay="200ms"
										data-wow-duration="1200ms"
									>
										<div class="icon-box">
											<div class="icon">
												<span class="flaticon-location"></span>
											</div>
											<h3>Office Address</h3>
											<ul>
												<li>
													12/A, Romania City Town Hall{" "}
													<br />
													New Joursey, UK
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div> */}
						{/* <div class="contact-form-area">
							<div class="sec-title text-center">
								<div class="sub-title">Write Here</div>
								<h2>Get In Touch</h2>
							</div>
							<!-- Contact Form-->
							<div class="contact-form">
								<form
									method="post"
									action="http://azim.commonsupport.com/Finandox/sendemail.php"
									id="contact-form"
								>
									<div class="row clearfix">
										<div class="col-md-6 form-group">
											<label for="name">
												Enter your name
											</label>
											<input
												type="text"
												name="username"
												id="name"
												placeholder="Enter name here......"
												required=""
											/>
											<i class="fas fa-user"></i>
										</div>

										<div class="col-md-6 form-group">
											<label for="email">
												Enter your email
											</label>
											<input
												type="email"
												name="email"
												id="email"
												placeholder="Enter email here......"
												required=""
											/>
											<i class="fas fa-envelope"></i>
										</div>

										<div class="col-md-12 form-group">
											<label for="message">
												Enter your message
											</label>
											<textarea
												name="message"
												id="message"
												placeholder="Enter message here......"
											></textarea>
											<i class="fas fa-edit"></i>
										</div>

										<div class="col-md-12 form-group">
											<button
												class="theme-btn btn-style-one"
												type="submit"
												name="submit-form"
											>
												<span class="btn-title">
													Get In Touch
												</span>
											</button>
										</div>
									</div>
								</form>
							</div>
						</div> */}
					</div>
				</section>

				<Footer2 />
			</>
		)
	}
}
export default Index3
